import {
  mdiAccountCircle,
  mdiDesktopMac,
  mdiGithub,
  mdiLock,
  mdiAlertCircle,
  mdiMonitorShimmer,
  mdiSquareEditOutline,
  mdiTable,
  mdiViewList,
  mdiTelevisionGuide,
  mdiResponsive,
  mdiFaceManProfile,
  mdiBorderColor,
  mdiChatOutline,
  mdiChartTimelineVariantShimmer,
  mdiHelpCircleOutline,
  mdiCart,
  mdiFormatListBulleted,
  mdiAccountGroup,
  mdiMessageText,
  mdiDomain,
  mdiViewDashboardOutline,
  mdiContentCopy,
  mdiCurrencyUsd,
  mdiShimmer,
  mdiListStatus,
  mdiWalk,
  mdiBookshelf,
  mdiEmailFast,
  mdiHammer,
  mdiFeatureSearch,
  mdiCommentQuote,
  mdiFirework,
  mdiPencil,
  mdiStore24Hour,
  mdiAccountPlus,
  mdiCogOutline,
  mdiBrush,
  mdiWalletOutline
} from "@mdi/js";
import { mdiPlusCircleOutline } from '@mdi/js';

export default [
  // "Verify",
  // [{
  //   to: "/verify",
  //   label: "Verify e-mail",
  //   icon: mdiEmailFast,
  //   updateMark: true,
  // }],
  "General",
  [
    {
      to: "/profilev2",
      label: "Profile",
      icon: mdiFaceManProfile,
      updateMark: true,
    },
  ],
  //   {
  //     to: "/pricing",
  //     label: "Functionality",
  //     icon: mdiWalk,
  //     updateMark: true,
  //   },
  //  
  // ],
  // "Tutorial",
  // [
  //   {
  //     href: "https://wiki.bumper-oo.com",
  //     target: "_blank",
  //     label: "Getting Started",
  //     icon: mdiBookshelf,
  //     updateMark: true,
  //   }
  // ],
  "Design",
  [
    {
      to: "/",
      label: "(Re)design",
      icon: mdiBrush,
      updateMark: true,
    },
  ],
  //   {
  //     to: "/accounts",
  //     label: "Accounts",
  //     icon: mdiAccountPlus,
  //     updateMark: true,
  //   },
  //   {
  //     to: "/automation",
  //     label: "Automations",
  //     icon: mdiCogOutline,
  //     updateMark: true,
  //   }
  // ],
  // "Builder",
  // [
  //   {
  //     to: "/builder",
  //     label: "Request Builder",
  //     icon: mdiHammer,
  //     updateMark: true,
  //   }
  // ],
  "Balance",
  [
    {
      to: "/products",
      label: "Replenish",
      icon: mdiWalletOutline,
      updateMark: true,
    }
  ],
  // "Social",
  // [
  //   {
  //     href: "https://discord.gg/PybHCUUKVM",
  //     label: "Join Discord",
  //     target: "_blank",
  //     icon: mdiChatOutline,
  //     updateMark: true,
  //   },
  //   {
  //     href: "https://toplistbot.com",
  //     label: "AutoVoter",
  //     target: "_blank",
  //     icon: mdiStore24Hour,
  //     updateMark: true,
  //   },
  // ]
  // [
  //   {
  //     to: '/tables',
  //     label: 'Tables',
  //     icon: mdiTable,
  //     updateMark: true
  //   },
  //   {
  //     to: '/forms',
  //     label: 'Forms',
  //     icon: mdiSquareEditOutline
  //   },
  //   {
  //     to: '/ui',
  //     label: 'UI',
  //     icon: mdiTelevisionGuide
  //   },
  //   {
  //     to: '/responsive',
  //     label: 'Responsive',
  //     icon: mdiResponsive
  //   },
  //   {
  //     to: '/profile',
  //     label: 'Profile',
  //     icon: mdiAccountCircle
  //   },
  //   {
  //     to: '/login',
  //     label: 'Login',
  //     icon: mdiLock
  //   },
  //   {
  //     to: '/error',
  //     label: 'Error',
  //     icon: mdiAlertCircle
  //   },
  //   {
  //     label: 'Submenus',
  //     subLabel: 'Submenus Example',
  //     icon: mdiViewList,
  //     menu: [
  //       {
  //         label: 'Sub-item One'
  //       },
  //       {
  //         label: 'Sub-item Two'
  //       }
  //     ]
  //   }
  // ],
  // 'About',
  // [

  // ],
];
