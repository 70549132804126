import axios from 'axios';
import axiosInstance from "./api";
import authHeader from "./auth-header";

const API_URL = window.location.href.includes("localhost")
  ? "http://127.0.0.1:8000/api/"
  : "https://indesignify-backend-uuvarjp72q-ew.a.run.app/api/";

class ProductsService {

  /**
   * Get accounts
   * @returns 
   */
  getProducts() {
    return axiosInstance.get(API_URL + 'products');
  }

}

export default new ProductsService();